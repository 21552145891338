<template>
  <div id="divAddRemoveCardWrapper" class="content-layout-fixer">
    <div id="divAddCard" class="template-wrapper">
      <!-- <iframe class="iframe_pos" id="my_iframe" name="my_iframe" src=""></iframe> -->
      <div class="template-wrapper-buttons" v-if="userCanAccessIyzico">
        <h1 class="cardInformation">Kredi Kartı</h1>
        <a
          href="javascript:void(0)"
          class="template-wrapper-buttons-add"
          @click="handleSubmit('addIyzicoCard')"
        >
          <VueText sizeLevel="7" weightLevel="2" color="grey-40">Ekle</VueText>
          <img src="@/assets/icons/add-circle.png" alt="icon" />
        </a>
        <a
          href="javascript:void(0)"
          class="template-wrapper-buttons-remove"
          @click="handleSubmit('addIyzicoCard')"
        >
          <VueText sizeLevel="7" weightLevel="2" color="grey-40">Çıkar</VueText>
          <img src="@/assets/icons/remove-circle.png" alt="icon" />
        </a>
      </div>
      <div class="template-wrapper-buttons" v-if="userCanAccessGaranti">
        <h2 class="cardInformation">Diğer Ticari Kartlar</h2>
        <a
          href="javascript:void(0)"
          class="template-wrapper-buttons-add"
          @click="handleSubmit('addCard')"
        >
          <VueText sizeLevel="7" weightLevel="2" color="grey-40">Ekle</VueText>
          <img src="@/assets/icons/add-circle.png" alt="icon" />
        </a>
        <a
          href="javascript:void(0)"
          class="template-wrapper-buttons-remove"
          @click="handleSubmit('removeCard')"
        >
          <VueText sizeLevel="7" weightLevel="2" color="grey-40">Çıkar</VueText>
          <img src="@/assets/icons/remove-circle.png" alt="icon" />
        </a>
      </div>
      <div class="template-wrapper-add">
        <form name="addIyzicoCard" id="addIyzicoCard" :action="iyzicoCardPageUrl" method="GET">
          <!-- <input type="hidden" id="testData" name="testData" :value="testData" /> -->
        </form>
      </div>
      <div class="template-wrapper-add">
        <form name="myform" id="addCard" :action="addUrl" method="POST" target="_self">
          <input
            type="hidden"
            id="secure3dsecuritylevel"
            name="secure3dsecuritylevel"
            :value="addFormData.secure3dsecuritylevel"
          />
          <input type="hidden" id="mode" name="mode" :value="addFormData.mode" />
          <input type="hidden" id="identity" name="identity" :value="addFormData.identity" />
          <input name="orderid" type="hidden" id="orderid" :value="addFormData.orderid" />
          <input
            name="cardrelationid"
            type="hidden"
            id="cardrelationid"
            :value="addFormData.cardrelationid"
          />
          <input name="apiversion" type="hidden" id="apiversion" :value="addFormData.apiversion" />
          <input
            name="terminalprovuserid"
            type="hidden"
            id="terminalprovuserid"
            :value="addFormData.terminalprovuserid"
          />
          <input
            name="terminaluserid"
            type="hidden"
            id="terminaluserid"
            :value="addFormData.terminaluserid"
          />
          <input name="terminalid" type="hidden" id="terminalid" :value="addFormData.terminalid" />
          <input
            name="terminalmerchantid"
            type="hidden"
            id="terminalmerchantid"
            :value="addFormData.terminalmerchantid"
          />
          <input
            type="hidden"
            id="customeripaddress"
            name="customeripaddress"
            :value="addFormData.customeripaddress"
          />
          <input
            type="hidden"
            id="customeremailaddress"
            name="customeremailaddress"
            :value="addFormData.customeremailaddress"
          />
          <input type="hidden" id="txntype" name="txntype" :value="addFormData.txntype" />
          <input name="txnamount" type="hidden" id="txnamount" :value="addFormData.txnamount" />
          <input
            name="txncurrencycode"
            type="hidden"
            id="txncurrencycode"
            :value="addFormData.txncurrencycode"
          />
          <input
            name="companyname"
            type="hidden"
            id="companyname"
            :value="addFormData.companyname"
          />
          <input
            name="txninstallmentcount"
            type="hidden"
            id="txninstallmentcount"
            :value="addFormData.txninstallmentcount"
          />
          <input type="hidden" id="successurl" name="successurl" :value="addFormData.successurl" />
          <input type="hidden" id="errorurl" name="errorurl" :value="addFormData.errorurl" />
          <input
            name="secure3dhash"
            type="hidden"
            id="secure3dhash0"
            :value="addFormData.secure3dhash"
          />
          <input name="txnmotoind" type="hidden" id="txnmotoind" :value="addFormData.txnmotoind" />
          <input name="mobilind" type="hidden" id="mobilind" :value="addFormData.mobilind" />
          <input
            type="hidden"
            id="txntimestamp"
            name="txntimestamp"
            :value="addFormData.txntimestamp"
          />
          <input type="hidden" id="Lang" name="lang" :value="addFormData.lang" />
          <input
            name="refreshtime"
            type="hidden"
            id="refreshtime"
            :value="addFormData.refreshtime"
          />
          <input
            type="hidden"
            id="commercialcardduedate"
            name="commercialcardduedate"
            :value="addFormData.commercialcardduedate"
          />
          <input
            name="commercialcardamount"
            type="hidden"
            id="commercialcardamount"
            :value="addFormData.commercialcardamount"
          />
        </form>
      </div>
      <div class="template-wrapper-remove">
        <form name="removeCard" id="removeCard" :action="removeUrl" method="POST" target="_self">
          <input
            type="hidden"
            id="secure3dsecuritylevel"
            name="secure3dsecuritylevel"
            :value="removeFormData.secure3dsecuritylevel"
          />
          <input type="hidden" id="mode" name="mode" :value="removeFormData.mode" />
          <input
            type="hidden"
            id="cardnumber"
            name="cardnumber"
            :value="removeFormData.cardnumber"
          />
          <input
            type="hidden"
            id="cardexpiredatemonth"
            name="cardexpiredatemonth"
            :value="removeFormData.cardexpiredatemonth"
          />
          <input
            type="hidden"
            id="cardexpiredateyear"
            name="cardexpiredateyear"
            :value="removeFormData.cardexpiredateyear"
          />
          <input
            type="hidden"
            id="Cardcard_ref"
            name="Cardcard_ref"
            :value="removeFormData.Cardcard_ref"
          />
          <input type="hidden" id="identity" name="identity" :value="removeFormData.identity" />
          <input
            type="hidden"
            id="txnsubtype"
            name="txnsubtype"
            :value="removeFormData.txnsubtype"
          />
          <input type="hidden" id="cardcvv2" name="cardcvv2" :value="removeFormData.cardcvv2" />
          <input name="orderid" type="hidden" id="orderid" :value="removeFormData.orderid" />
          <input
            name="cardrelationid"
            type="hidden"
            id="cardrelationid"
            :value="removeFormData.cardrelationid"
          />
          <input
            name="apiversion"
            type="hidden"
            id="apiversion"
            :value="removeFormData.apiversion"
          />
          <input
            name="terminalprovuserid"
            type="hidden"
            id="terminalprovuserid"
            :value="removeFormData.terminalprovuserid"
          />
          <input
            name="terminaluserid"
            type="hidden"
            id="terminaluserid"
            :value="removeFormData.terminaluserid"
          />
          <input
            name="terminalid"
            type="hidden"
            id="terminalid"
            :value="removeFormData.terminalid"
          />
          <input
            name="terminalmerchantid"
            type="hidden"
            id="terminalmerchantid"
            :value="removeFormData.terminalmerchantid"
          />
          <input
            type="hidden"
            id="customeripaddress"
            name="customeripaddress"
            :value="removeFormData.customeripaddress"
          />
          <input
            type="hidden"
            id="customeremailaddress"
            name="customeremailaddress"
            :value="removeFormData.customeremailaddress"
          />
          <input type="hidden" id="txntype" name="txntype" :value="removeFormData.txntype" />
          <input name="txnamount" type="hidden" id="txnamount" :value="removeFormData.txnamount" />
          <input
            name="txncurrencycode"
            type="hidden"
            id="txncurrencycode"
            :value="removeFormData.txncurrencycode"
          />
          <input
            name="companyname"
            type="hidden"
            id="companyname"
            :value="removeFormData.companyname"
          />
          <input
            name="txninstallmentcount"
            type="hidden"
            id="txninstallmentcount"
            :value="removeFormData.txninstallmentcount"
          />
          <input
            type="hidden"
            id="successurl"
            name="successurl"
            :value="removeFormData.successurl"
          />
          <input type="hidden" id="errorurl" name="errorurl" :value="removeFormData.errorurl" />
          <input
            name="secure3dhash"
            type="hidden"
            id="secure3dhash"
            :value="removeFormData.secure3dhash"
          />
          <input
            name="txnmotoind"
            type="hidden"
            id="txnmotoind"
            :value="removeFormData.txnmotoind"
          />
          <input name="mobilind" type="hidden" id="mobilind" :value="removeFormData.mobilind" />
          <input
            type="hidden"
            id="txntimestamp"
            name="txntimestamp"
            :value="removeFormData.txntimestamp"
          />
          <input type="hidden" id="Lang" name="lang" :value="removeFormData.lang" />
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import { Finance } from '@/services/Api/index';

export default {
  name: 'AddRemoveCreditCard',
  data() {
    return {
      addUrl: '',
      removeUrl: '',
      iyzicoCardPageUrl: '',
      userCanAccessIyzico: '',
      userCanAccessGaranti: '',
      addFormData: {
        secure3dsecuritylevel: '',
        mode: '',
        identity: '',
        orderid: '',
        cardrelationid: '',
        apiversion: '',
        terminalprovuserid: '',
        terminaluserid: '',
        terminalid: '',
        terminalmerchantid: '',
        customeripaddress: '',
        customeremailaddress: '',
        txntype: '',
        txnamount: '',
        txncurrencycode: '',
        companyname: '',
        txninstallmentcount: '',
        successurl: '',
        errorurl: '',
        secure3dhash: '',
        txnmotoind: '',
        mobilind: '',
        txntimestamp: '',
        lang: '',
        refreshtime: '',
        commercialcardduedate: '',
        commercialcardamount: '',
      },
      removeFormData: {
        secure3dsecuritylevel: '',
        mode: '',
        cardnumber: '',
        cardexpiredatemonth: '',
        cardexpiredateyear: '',
        Cardcard_ref: '',
        identity: '',
        txnsubtype: '',
        cardcvv2: '',
        orderid: '',
        cardrelationid: '',
        apiversion: '',
        terminalprovuserid: '',
        terminaluserid: '',
        terminalid: '',
        terminalmerchantid: '',
        customeripaddress: '',
        customeremailaddress: '',
        txntype: '',
        txnamount: '',
        txncurrencycode: '',
        companyname: '',
        txninstallmentcount: '',
        successurl: '',
        errorurl: '',
        secure3dhash: '',
        txnmotoind: '',
        mobilind: '',
        txntimestamp: '',
        lang: '',
      },
    };
  },
  components: {
    VueText,
  },
  created() {
    Finance.getBosPermission().then(res => {
      const {
        Data: { userCanAccessIyzico, userCanAccessGaranti },
      } = res.data;

      this.userCanAccessIyzico = userCanAccessIyzico;
      this.userCanAccessGaranti = userCanAccessGaranti;
    });
    Finance.getIyzicoCardPageUrl().then(res => {
      const {
        Data: { cardPageUrl },
      } = res.data;

      this.iyzicoCardPageUrl = cardPageUrl;
    });
    Finance.getCreditCardModels().then(res => {
      const {
        Data: { addCardParams, removeCardParams },
      } = res.data;

      this.addFormData.secure3dsecuritylevel = addCardParams.secure3dSecurityLevel;
      this.addFormData.mode = addCardParams.mode;
      this.addFormData.identity = addCardParams.identity;
      this.addFormData.orderid = addCardParams.cardOrderID;
      this.addFormData.cardrelationid = addCardParams.cardReationID;
      this.addFormData.apiversion = addCardParams.apiVersion;
      this.addFormData.terminalprovuserid = addCardParams.terminalProvUserID;
      this.addFormData.terminaluserid = addCardParams.terminalUserID;
      this.addFormData.terminalid = addCardParams.terminalID;
      this.addFormData.terminalmerchantid = addCardParams.terminalMerchantID;
      this.addFormData.customeripaddress = addCardParams.customerIpAdress;
      this.addFormData.customeremailaddress = addCardParams.customerEmailAddress;
      this.addFormData.txntype = addCardParams.txnType;
      this.addFormData.txnamount = addCardParams.txnAmount;
      this.addFormData.txncurrencycode = addCardParams.txnCurrencyCode;
      this.addFormData.companyname = addCardParams.companyName;
      this.addFormData.txninstallmentcount = addCardParams.txnInstallmentCount;
      this.addFormData.successurl = addCardParams.successUrl;
      this.addFormData.errorurl = addCardParams.errorUrl;
      this.addFormData.secure3dhash = addCardParams.secure3DHash;
      this.addFormData.txnmotoind = addCardParams.txnmotoind;
      this.addFormData.mobilind = addCardParams.mobilind;
      this.addFormData.txntimestamp = addCardParams.txnTimestamp;
      this.addFormData.lang = addCardParams.lang;
      this.addFormData.refreshtime = addCardParams.refreshTime;
      this.addFormData.commercialcardduedate = '';
      this.addFormData.commercialcardamount = addCardParams.commercialCardAmount;

      this.addUrl = addCardParams.url;

      this.removeFormData.secure3dsecuritylevel = removeCardParams.secure3dSecurityLevel;
      this.removeFormData.mode = removeCardParams.mode;
      this.removeFormData.cardnumber = removeCardParams.cardNumber;
      this.removeFormData.cardexpiredatemonth = removeCardParams.cardExpireMonth;
      this.removeFormData.cardexpiredateyear = removeCardParams.cardExpireYear;
      this.removeFormData.Cardcard_ref = removeCardParams.cardCard_ref;
      this.removeFormData.identity = removeCardParams.identity;
      this.removeFormData.txnsubtype = removeCardParams.txnSubType;
      this.removeFormData.cardcvv2 = removeCardParams.cardCvv2;
      this.removeFormData.orderid = removeCardParams.orderID;
      this.removeFormData.cardrelationid = removeCardParams.cardRelationID;
      this.removeFormData.apiversion = removeCardParams.apiVersion;
      this.removeFormData.terminalprovuserid = removeCardParams.terminalproviderUserID;
      this.removeFormData.terminaluserid = removeCardParams.terminalUserID;
      this.removeFormData.terminalid = removeCardParams.terminalID;
      this.removeFormData.terminalmerchantid = removeCardParams.terminalMerchantID;
      this.removeFormData.customeripaddress = removeCardParams.customerIpAdress;
      this.removeFormData.customeremailaddress = removeCardParams.customerEmailAdress;
      this.removeFormData.txntype = removeCardParams.txnType;
      this.removeFormData.txnamount = removeCardParams.txnAmount;
      this.removeFormData.txncurrencycode = removeCardParams.txnCurrencyCode;
      this.removeFormData.companyname = removeCardParams.companyName;
      this.removeFormData.txninstallmentcount = removeCardParams.txnInstallmentCount;
      this.removeFormData.successurl = removeCardParams.successUrl;
      this.removeFormData.errorurl = removeCardParams.errorUrl;
      this.removeFormData.secure3dhash = removeCardParams.secure3dHash;
      this.removeFormData.txnmotoind = removeCardParams.txnMotoinD;
      this.removeFormData.mobilind = removeCardParams.mobilind;
      this.removeFormData.txntimestamp = removeCardParams.txtTimeStamp;
      this.removeFormData.lang = removeCardParams.lang;

      this.removeUrl = removeCardParams.url;
    });
  },
  methods: {
    handleSubmit(formId) {
      document.getElementById(formId).submit();
    },
  },
};
</script>
<style lang="scss" scoped>
.template-wrapper {
  margin: 60px 0;
  height: 100%;

  &-buttons {
    padding: 0 25px;
    a {
      border-bottom: 0.5px solid palette-color-level('grey', '20');
      padding: 25px 0;
      display: flex;
      text-decoration: none;
      justify-content: space-between;
      align-items: center;
      &:first-child {
        border-top: 0.5px solid palette-color-level('grey', '20');
      }
    }
  }

  .cardInformation {
    font-size: 25px;
    font-weight: bold;
    border-bottom: 0.5px solid palette-color-level('grey', '20');
    padding: 25px 0;
    &:not(:first-child) {
      margin-top: 30px;
    }
  }
}
</style>
